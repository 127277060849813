@import 'variables';
/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-radius {
  border-radius: 0;
}

.no-border{
  border: none !important;
}

.no-decoration {
  text-decoration: none !important;
}

.no-events {
  pointer-events: none !important;
}
.line-through{
  text-decoration: line-through !important;
}

// Soft hide (don't remove from DOM flow)
.hidden {
  visibility: hidden;
  opacity: 0;
}

[fill-width] {
  min-width: 100%;
}

[fill-height] {
  min-height: 100%;
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100%;
}

.link-disabled {
  pointer-events: none;
}

// Images in links
a {

  img {
    //display: block;
    outline: none;
  }
}

// Responsive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}

// Scrollable
.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.only-x {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.only-y {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Flex Scrollable (without display:block)
.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.only-x {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.only-y {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Background Icon
.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

// Classic vertical align
.align-vertical {

  &:before {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle;
  }

  .align-vertical-top,
  .align-vertical-middle,
  .align-vertical-bottom {
    display: inline-block;
  }

  .align-vertical-top {
    vertical-align: top;
  }

  .align-vertical-middle {
    vertical-align: middle;
  }

  .align-vertical-bottom {
    vertical-align: bottom;
  }
}

.align-column {
  display: flex;
  flex-direction: column;

  &-left {
    align-items: flex-start;
  }
}

// Padding helpers
// Multiples of 5, max:100
@for $i from 0 through 20 {

  .padding-#{$i * 5},
  .p-#{$i * 5} {
    padding: #{($i * 5) + 'px'};
  }

  .padding-top-#{$i * 5},
  .pt-#{$i * 5} {
    padding-top: #{($i * 5) + 'px'};
  }

  .padding-right-#{$i * 5},
  .pr-#{$i * 5} {
    padding-right: #{($i * 5) + 'px'};
  }

  .padding-bottom-#{$i * 5},
  .pb-#{$i * 5} {
    padding-bottom: #{($i * 5) + 'px'};
  }

  .padding-left-#{$i * 5},
  .pl-#{$i * 5} {
    padding-left: #{($i * 5) + 'px'};
  }

  .padding-horizontal-#{$i * 5},
  .ph-#{$i * 5} {
    padding-left: #{($i * 5) + 'px'};
    padding-right: #{($i * 5) + 'px'};
  }

  .padding-vertical-#{$i * 5},
  .pv-#{$i * 5} {
    padding-top: #{($i * 5) + 'px'};
    padding-bottom: #{($i * 5) + 'px'};
  }
}

// Multiples of 8, Max: 96, Excluding 0 and multiples of 5
@for $i from 1 through 12 {

  @if (($i * 8) % 5 != 0) {

    .padding-#{$i * 8},
    .p-#{$i * 8} {
      padding: #{($i * 8) + 'px'};
    }

    .padding-top-#{$i * 8},
    .pt-#{$i * 8} {
      padding-top: #{($i * 8) + 'px'};
    }

    .padding-right-#{$i * 8},
    .pr-#{$i * 8} {
      padding-right: #{($i * 8) + 'px'};
    }

    .padding-bottom-#{$i * 8},
    .pb-#{$i * 8} {
      padding-bottom: #{($i * 8) + 'px'};
    }

    .padding-left-#{$i * 8},
    .pl-#{$i * 8} {
      padding-left: #{($i * 8) + 'px'};
    }

    .padding-horizontal-#{$i * 8},
    .ph-#{$i * 8} {
      padding-left: #{($i * 8) + 'px'};
      padding-right: #{($i * 8) + 'px'};
    }

    .padding-vertical-#{$i * 8},
    .pv-#{$i * 8} {
      padding-top: #{($i * 8) + 'px'};
      padding-bottom: #{($i * 8) + 'px'};
    }
  }
}

// Margin helpers
// Multiples of 5, max:100
@for $i from 0 through 20 {

  .margin-#{$i * 5},
  .m-#{$i * 5} {
    margin: #{($i * 5) + 'px'};
  }

  .margin-top-#{$i * 5},
  .mt-#{$i * 5} {
    margin-top: #{($i * 5) + 'px'};
  }

  .margin-right-#{$i * 5},
  .mr-#{$i * 5} {
    margin-right: #{($i * 5) + 'px'};
  }

  .margin-bottom-#{$i * 5},
  .mb-#{$i * 5} {
    margin-bottom: #{($i * 5) + 'px'};
  }

  .margin-left-#{$i * 5},
  .ml-#{$i * 5} {
    margin-left: #{($i * 5) + 'px'};
  }

  .margin-horizontal-#{$i * 5},
  .mh-#{$i * 5} {
    margin-left: #{($i * 5) + 'px'};
    margin-right: #{($i * 5) + 'px'};
  }

  .margin-vertical-#{$i * 5},
  .mv-#{$i * 5} {
    margin-top: #{($i * 5) + 'px'};
    margin-bottom: #{($i * 5) + 'px'};
  }
}

.mt-188 {
  margin-top: 188px;
}

// Multiples of 8, Max: 96, Excluding 0 and multiples of 5
@for $i from 1 through 12 {

  @if (($i * 8) % 5 != 0) {

    .margin-#{$i * 8},
    .m-#{$i * 8} {
      margin: #{($i * 8) + 'px'};
    }

    .margin-top-#{$i * 8},
    .mt-#{$i * 8} {
      margin-top: #{($i * 8) + 'px'};
    }

    .margin-right-#{$i * 8},
    .mr-#{$i * 8} {
      margin-right: #{($i * 8) + 'px'};
    }

    .margin-bottom-#{$i * 8},
    .mb-#{$i * 8} {
      margin-bottom: #{($i * 8) + 'px'};
    }

    .margin-left-#{$i * 8},
    .ml-#{$i * 8} {
      margin-left: #{($i * 8) + 'px'};
    }

    .margin-horizontal-#{$i * 8},
    .mh-#{$i * 8} {
      margin-left: #{($i * 8) + 'px'};
      margin-right: #{($i * 8) + 'px'};
    }

    .margin-vertical-#{$i * 8},
    .mv-#{$i * 8} {
      margin-top: #{($i * 8) + 'px'};
      margin-bottom: #{($i * 8) + 'px'};
    }
  }
}

// Height and width helpers
// Multiples of 5, Max: 500
@for $i from 0 through 100 {

  .height-#{$i * 5},
  .h-#{$i * 5} {
    height: #{($i * 5) + 'px'} !important;
    min-height: #{($i * 5) + 'px'} !important;
    max-height: #{($i * 5) + 'px'} !important;
  }

  .width-#{$i * 5},
  .w-#{$i * 5} {
    width: #{($i * 5) + 'px'} !important;
    min-width: #{($i * 5) + 'px'} !important;
    max-width: #{($i * 5) + 'px'} !important;
  }
}

// Height and width helpers
// Multiples of 8, Max: 800, Excluding 0 and multiples of 5
@for $i from 1 through 100 {

  @if (($i * 8) % 5 != 0) {

    .height-#{$i * 8},
    .h-#{$i * 8} {
      height: #{($i * 8) + 'px'} !important;
      min-height: #{($i * 8) + 'px'} !important;
      max-height: #{($i * 8) + 'px'} !important;
    }

    .width-#{$i * 8},
    .w-#{$i * 8} {
      width: #{($i * 8) + 'px'} !important;
      min-width: #{($i * 8) + 'px'} !important;
      max-width: #{($i * 8) + 'px'} !important;
    }
  }
}

// Half width helper for angular material

.width-half{
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 50% !important;
  flex: 1 1 50% !important;
  max-width: 50% !important;
  max-height: 100% !important;
  box-sizing: border-box !important;
}

// Border helpers
$border-style: 1px solid #979797;

.border,
.b {
  border: $border-style;
}

.border-top,
.bt {
  border-top: $border-style;
}

.border-right,
.br {
  border-right: $border-style;
}

.border-bottom,
.bb {
  border-bottom: $border-style;
}

.border-left,
.bl {
  border-left: $border-style;
}

.border-horizontal,
.bh {
  border-left: $border-style;
  border-right: $border-style;
}

.border-vertical,
.bv {
  border-top: $border-style;
  border-bottom: $border-style;
}

// Text format helpers
.text-italic {
  font-style: italic;
}

.text-semibold {
  font-weight: 600;
}

.text-bold, strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

// Text align helpers
.text-left {
  text-align: left;
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right;
}

// Font weight helpers
@for $weight from 1 through 9 {

  .font-weight-#{$weight * 100} {
    font-weight: #{$weight * 100};
  }
}

// Font size helpers
@for $size from 1 through 60 {

  .font-size-#{$size * 2} {
    font-size: #{$size * 2}px;
  }
}

// Line height helpers
@for $lineHeight from 1 through 60 {

  .line-height-#{$lineHeight * 2} {
    line-height: #{$lineHeight * 2}px;
  }
}

// Boxed text
.text-boxed {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

// Boxed text light
.text-boxed-light {
  @extend .text-boxed;
  background-color: rgba(255, 255, 255, 0.7);
}

// Truncate
.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Nowrap
.text-nowrap {
  white-space: nowrap;
}

// Word Break
.text-word-break {
  word-break: break-word;
}

.flex {
  display: flex;
}
.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-center {
  align-items: center;
}

// Border-radius helpers
@for $i from 1 through 12 {

  .brad-#{$i} {
    border-radius: #{$i + 'px'}
  }
}

.hidden-block {
  display: none;
}

.visible-block {
  display: block;
}

.only-desktop {
  display: block;

  @media only screen and (max-width: 666px) {
    display: none;
  }
}

.only-mobile {
  display: none;

  @media only screen and (max-width: 666px) {
    display: block;
  }
}
