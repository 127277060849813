#blue-ring {
  width: 60%;
  position: absolute;
  right: -23rem;
  top: -65rem;
  z-index: -1;
}


#water-effect {
  top: -15rem;
}

.loader {

  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 99990;
}

#after-loader {
  transform: scale(1.5);
}

/* END: animcations */

.letter-space {
  letter-spacing: 10px;
}

.hero-img {
  z-index: 0;
  position: sticky;
  top: 0;
  left: 0;
  background: url('./../../assets/hero-img-main.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}


/* START: buttons */
.btn-danger {
  background-color: var(--color-danger) !important;
  border: 2px solid var(--color-danger) !important;
}

.btn-danger:hover {
  background-color: var(--color-danger-light) !important;
  border: 2px solid var(--color-danger-light) !important;
}


.navbar {
  z-index: 20;
}
#btn-buy,
.navbar-brand {
  z-index: 100;
}

.lang {
  width: 30px;
  height: 30px;
  padding-left: 35px;
  text-transform: capitalize !important;
  font-size: 25px !important;
  background: url(./../../assets/lang.svg) no-repeat left center;
  background-size: contain;
}
.lang:hover {
  opacity: 0.8;
}


.maopen #collapse1 {
  display:block;
}


/* END: buttons */


/* START: backgrounds */
.bg-primary {
  background-color: var(--color-primary) !important;
}

/* START: backgrounds */


/* START: text colors */
.text-primary-light {
  color: var(--color-primary-light) !important;
}

.text-warning-gold {
  color: var(--color-warning-gold) !important;
}

/* END: text colors*/

/* START: cards */

.card-icon {
  width: 22%;
  margin: auto;
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
}



.time-clip {
  right: -25%;
  top: -60px;
  transform: scale(1.2);
  z-index: -2;
}

.yellow-ring {
  top: -15px;
  right: -70%;
  display: none !important;
  transform: scale(0.7);
}

/* START: cards */

/* rings */
#primary-ring {
  position: relative;
  top: -440px;
  left: -150px;
  z-index: -10;
}

.hero-img.inner_subheader {
  background: none;
}
.hero-img.inner_subheader .subheader_banner {
  top: 0;
  left: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
}
.hero-img.inner_subheader {
  background: none;
  max-height: 600px;
}


.cyan_rides #primary-ring {
  top: -5px;
  left: -80px;
  z-index: 1;
}
.cyan_rides.privacy_page #half-blue-ring {
  top: -370px;
  right: -170px;
  width: auto !important;
}
.cyan_rides .page_content::after {
  display: none;
}
.cyan_rides.privacy_page #content-section::after {
  display: none;
}





/* ===============================================

Dress Code Pages

=============================================== */

.swimming_dress_code h1,
.swimming_dress_code h2,
.swimming_dress_code h3,
.swimming_dress_code h4,
.swimming_dress_code h5,
.swimming_dress_code h6 {
  font-family: 'AktivGrotesk-Bold' !important;
}
.swimming_dress_code .ar_text {
  display: block;
  font-family: 'Kaff' !important;
}

.sdc_head {
  padding: 30px 20px;
}
.sdc_head .col-md-6:first-child {
  order: 12;
  text-align: right;
}
.sdc_head h1 {
  color: #074e8b;
  font-size: 30px;
  padding-top: 50px;
}
.sdc_head h1 .ar_text {
  color: #d6006d;
}


.dress_for {
  text-align: center;
  margin-top: -160px;
}
.dress_for p {
  font-size: 20px;
  color: #074e8b;
  margin-bottom: 5px;
}

.swimwear_guides {
  text-align: center;
}
.swimwear_guides h2 {
  color: #074e8b;
  font-size: 36px;
}

.dress_allowed {
  padding-bottom: 8px;
}
.dress_allowed img {
  padding: 0 10px;
  display: inline-block;
  vertical-align: bottom;
}
.dress_not_allowed div {
  padding: 8px 0;
}
.dress_not_allowed img {
  padding: 0 20px;
  width: 170px;
}
.dress_not_allowed h4 {
  font-size: 20px;
  color: #d6006d;
  padding-top: 20px;
  margin-bottom: 0;
  font-family: 'AktivGrotesk-Medium' !important;
}
.dress_not_allowed h4 .ar_text {
  font-size: 22px;
  padding-top: 10px;
}

.swim_notes {
  color: #004a98;
  font-size: 18px;
  padding: 20px 30px 20px 40px;
}
.swim_notes .col-md-6:first-child p {
  padding-left: 2rem;
  font-family: 'AktivGrotesk-Medium';
}
.swim_notes .col-md-6:last-child {
  direction: rtl;
  text-align: right;
}
.swim_notes .col-md-6:last-child p {
  padding-right: 2rem;
}
.swim_notes li {
  color: #004a98;
  list-style: none;
  position: relative;
  margin-bottom: 15px;
  font-family: 'AktivGrotesk-Medium';
}
.swim_notes li::before {
  top: 42%;
  left: -30px;
  content: '';
  width: 15px;
  height: 15px;
  position: absolute;
  transform: translateY(-50%);
  background: url(./../../assets/list-icon.svg) no-repeat;
}
.swim_notes .col-md-6:last-child ul {
  padding-right: 2rem;
}
.swim_notes .col-md-6:last-child li::before {
  right: -30px;
  left: initial;
}



.men_dress img {
  vertical-align: middle;
}
.men_dress img:last-child {
  margin-bottom: 30px;
  margin-top: -30px;
}
.men_dress_not_allowed .col-md-auto {
  padding-left: 40px;
  padding-right: 40px;
}
.swimming_dress_code {
  padding-left: 10px;
  font-family: 'AktivGrotesk';
  background: #fff url(./../../assets/cornerl_pink_circle.svg) left top no-repeat;
  background-size: 100px;
}
.swimming_dress_code.for_kids {
  padding-bottom: 180px;
  background: #fff url(./../../assets/kids_bottom_circle.svg) no-repeat center bottom;
  background-size: 250px;
}
.swimming_dress_code.for_kids .dress_not_allowed {
  padding-top: 50px;
}
.color-green {
  color: #54b948 !important;
}

.dress_not_allowed h4 {
  font-size: 20px;
  color: #d6006d;
  padding-top: 20px;
  margin-bottom: 0;
  font-family: 'AktivGrotesk-Medium' !important;
}

.ar_text {
  font-weight: bold;
  font-family: 'Kaff' !important;
}

.swimming_dress_code .ar_text {
  display: block;
  font-family: 'Kaff' !important;
}
.dress_not_allowed h4 .ar_text {
  font-size: 22px;
  padding-top: 10px;
}

/* START: responsive screen  */
@media(max-width: 800px) {

  #card1,
  #card2,
  #card3,
  #card4 {
    height: 180px;
    margin: 35px 0;
  }



  .letter-space {
    letter-spacing: 5px;
  }

  #hero-img {
    height: unset;
  }

  /* #btn-buy {
      width: 100%;
  } */

  #water-effect {
    top: 0rem !important;
  }

  .hero-img {
    height: 100vh;
  }

  body {
    overflow-x: hidden !important;
  }

  #footer-clip {
    max-width: 110px;
    width: 100%;
    top: -91px;
    left: -30px;
    z-index: 1;
  }

  #blue-ring {
    width: 50%;
    right: -25;
    top: -50rem;
  }

  #content-section::before {
    background-color: #fff;
    position: unset;
  }

  #content-section {
    background-color: #fff;
    /* overflow-x: hidden; */
  }
}

@media(max-width: 400px) {

  .text-turbinado {
    font-size: 4.5rem !important;
  }

  #after-loader {
    transform: scale(4.5);
  }

  #water-effect {
    top: 0rem !important;
  }
}

@media (max-width: 850px) {
  #blue-ring {
    right: -12rem;
    top: -80rem;
  }
}

@media (max-width: 970px) {
  .time-clip {
    right: -25%;
    top: -23px !important;
    transform: scale(1.2);
    z-index: -2;
  }

  /* #content-section {
      overflow-x: hidden !important;
  } */

  #water-effect {
    top: -3rem;
  }
}
@media only screen and (max-width: 1367px) {

  #water-effect {
    top: -11rem;
  }
}
@media (max-width: 1285px) {

  #water-effect {
    top: -9rem;
  }

  .time-clip {
    right: -25%;
    top: -49px !important;
    transform: scale(1.2);
    z-index: -2;
  }
}

/* START: responsive screen  */

@media only screen and (max-width: 1280px) {

  #water-effect {
    top: -6rem;
  }

  .lp_blocks .col-md-5 > div {
    height: 280px !important;
  }
  .lp_blocks .col-md-5 > div {
    height: 230px !important;
  }
  #footer-clip {
    max-width: 70px;
    top: -60px;
    left: -20px;
  }

  .swimming_dress_code .container {
    max-width: 1100px;
  }
}

@media only screen and (max-width: 1024px) {

  section.rides_carousel .owl-stage-outer::before,
  section.rides_carousel .owl-stage-outer::after {
    display: none;
  }
}
@media only screen and (max-width: 991px) {

  .lp_blocks .row {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .lp_blocks .col-md-5 {
    width: 100%;
  }
  .lp_blocks .col-md-5 > div {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
  #footer-clip-path .container {
    max-width: none;
    padding: 0 30px;
  }
}
@media only screen and (max-width: 800px) {

  .lp_blocks .col-md-5:last-child > div::after {
    top: 80px;
    right: 100px;
    width: 40px;
    height: 40px;
  }
  #welcome-screen p br {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {

  #footer-clip-path .col-md-3 {
    width: 100%;
  }
  #footer-clip-path .col-md-3:first-child {
    padding-bottom: 30px;
  }
  #footer-clip-path .col-md-3:last-child {
    padding-top: 30px;
  }
  #footer-clip-path::after {
    width: 360px;
  }
}
@media only screen and (max-width: 767px) {

  .hero-img.inner_subheader .subheader_banner {
    height: 100%;
    position: absolute;
  }
  #btn-buy {
    margin-top: 0 !important;
  }

  .navbar .container-fluid {
    justify-content: space-between !important;
  }

  #welcome-screen {
    padding: 50px 30px 0 30px;
  }

  .lp_blocks {
    padding-top: 60px;
  }
  .lp_blocks .col-md-5:last-child > div::after {
    right: 20px;
  }

  #content-section .container > .row {
    margin-bottom: 0 !important;
    padding-bottom: 50px;
  }

  #footer-clip-path {
    padding-top: 40px !important;
  }
  #footer-clip-path::after {
    width: 150px;
    height: 60px;
  }
  #footer-clip-path .col-md-3:last-child {
    padding-bottom: 40px;
  }
  #footer-clip-path .navbar-nav  {
    text-align: center;
  }

  .page_content_inner {
    margin: 0;
  }
  section.rides_carousel {
    padding-top: 100px;
  }


  .sdc_head .col-md-6:first-child {
    order: unset;
    text-align: center;
  }
  .sdc_head .col-md-6:last-child {
    text-align: center;
  }
  .dress_for {
    margin-top: 0 !important;
  }
  .swim_notes {
    padding: 0 10px 0 0px;
  }
  .dress_not_allowed .col-md-auto {
    width: 50%;
  }
  .men_dress_not_allowed .col-md-auto {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 736px) {

  .navbar .container-fluid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }


  .text-turbinado {
    font-size: 6rem !important;
  }

  .men_dress img:last-child {
    margin-top: 0;
  }
}
@media only screen and (max-width:568px) {

  .hero-img {
    height: 600px;
    padding: 0 20px;
  }
  #welcome-screen {
    padding-left: 0;
    padding-right: 0;
  }
  .text-turbinado {
    font-size: 5rem !important;
  }
  #water-effect {
    top: 0rem !important;
  }

  #footer-clip-path {
    padding-top: 50px;
  }
}
@media only screen and (max-width:480px) {


  .window {
    z-index: 9999;
    position: absolute;
    left: 10px;
    top: 40px;
  }

  .hero-img {
    height: 660px;
  }
  #btn-buy, .navbar-brand {
    z-index: 100;
    width: 100%;
    text-align: center;
  }
  .lp_blocks .col-md-5:last-child > div::after {
    display: none !important;
  }

  .rides_carousel .owl-item:hover {
    min-width: auto !important;
  }
  .rides_carousel .owl-item::after {
    width: 50px;
    height: 50px;
    right: -10px;
  }
  .rides_carousel .owl-item::before {
    top: 0px;
    left: -10px;
    width: 50px;
    height: 50px;
  }


  .hero-img.inner_subheader, #content-section {
    overflow: hidden;
  }
}
@media only screen and (max-width:403px) {

  .text-turbinado {
    font-size: 4rem !important;
  }
  .lp_blocks .col-md-5 > div {
    height: 210px !important;
  }
  .lp_blocks p {
    font-size: 15px;
  }
}
