@import './../variables';

.datepicker {
  width: 100%;
  border-radius: 10px;
  border-color: $dark-blue;
}

.icon-button {
  font-size: 30px;
  background-color: white;
  border-radius: 15px;
}

.ticket-card {
  border-radius: 10px;
  margin-top: 20px;
  background-color: #F6F6F6;
}

.book-now {
  background-color: $primary;
  margin-top: 20px;
  height: 50px;
  border: none;
  border-radius: 10px;
}
.ant-btn-primary:hover, .ant-btn-primary:focus{
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
}

.get-code {
  background-color: $primary;
  border: none;

}

.calender-icon {
  width: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ticket-image {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  width: 100%;
  height: 246px;
  background-color: $dark-blue;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-image > .ant-image {
  width: 100%
}
.right-radius {
  border-radius: 0 15px 15px 0;
}
.left-radius {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0px !important;
  margin-left: 0 !important;
  margin-right: 3px !important;
  padding-right: 10px;
}

.ticket-details {
  margin-left: 5px;
  background-color: $dark-blue;
  min-height: 246px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.ticket-description {
  margin-left: -10px;
  margin-right: -10px;
}

.ticket-description > ul {
  line-height: 1.5em;
  list-style-image: url('./../../assets/tick_mark.svg');
  font-size: 10px;
  color: white;
}

.separator {
  width: 1px;
  height: 92%;
  background-color: #FFEE36;
}
.card-body {
  border-radius: 10px;
  background-color: $dark_blue;
  padding: 10px;
  color: white;
}
@media(max-width: 767px) {
  .ticket-image {
    border-radius: 15px 10px 0 0px;
  }
  .ticket-details {
    margin-right: 0px !important;
    margin-left: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
  }
  .ticket-image > .ant-image {
    width: 70%
  }
  .separator {
    height: 99%;
  }
}
@media(max-width: 400px){
  .ticket-description {
    margin-left: -20px;
  }
  .icon-button {
    font-size: 25px;
    background-color: white;
    border-radius: 13px;
  }
  .ant-btn-icon-only {
    width: auto;
  }
}

.family-icon {
  width: 30px;
  height: 30px;
}
.term-box .ant-checkbox-inner {
  border: 2px solid darkred;
}
.term-box .ant-checkbox-checked .ant-checkbox-inner {
  border: 2px solid #1890ff;
}
